import React from 'react'
import Layout from '@/components/Layout'
import { Link } from 'gatsby'
import '../styles/pages/404.scss'

const NotFoundPage = () => (
  <Layout>
    <section className='hero hero--small'>
      <div className='container'>
        <div className='section__title section__title--center text-center'>
          <h1 className='title-1'>404: Page Not Found</h1>
          <div className='mt-32 text-lg'>
            We can’t seem to find the page you’re looking for. Try going back to
            the previous page.
          </div>
          <Link to='/' className='btn btn-white mt-32'>
            Back to Home
          </Link>
        </div>
      </div>
    </section>
    <hr className='hr hr--left' />
  </Layout>
)

export default NotFoundPage
